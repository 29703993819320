import React from 'react';

import {
	Grid,
	CircleButton,
	Container,
	NumberedContent,
	Background,
	Spacer,
} from '../../../components';
import { useAssetsContext } from '../../../assetsContext';
import { getPagePathByTitle } from '../../../utils/helpers';

import styles from './collabSection.module.scss';

const renderButtons = (buttons) => (
	<div className={styles.circleButtons}>
		{buttons.map(({ text, link }, index) => (
			<CircleButton
				className={styles.circleButton}
				key={index}
				small
				link={link}
				text={text}
				hoverColor="white"
			/>
		))}
	</div>
);

const CollabSection = () => {
	const { locale, pages, acf = {} } = useAssetsContext();

	const buttons = [
		{
			text: acf.homepage_collab_links[0]?.text,
			link: getPagePathByTitle('About', locale, pages),
		},
		{
			text: acf.homepage_collab_links[1]?.text,
			link: getPagePathByTitle('About', locale, pages),
		},
		{
			text: acf.homepage_collab_links[2]?.text,
			link: getPagePathByTitle('About', locale, pages),
		},
	];

	return (
		<React.Fragment>
			<Spacer desktop={200} mobile={50} />
			<Container left={136} right={136}>
				<NumberedContent number={7} indent>
					<Grid>
						<Grid.Col width="md-12">
							<h2 className="h1">{acf.homepage_collab_title}</h2>
						</Grid.Col>
					</Grid>
					<div className="hidden-desktop">
						{/* <Spacer mobile={20} />
						<p className="f-large color-black-light">
							{description}
						</p> */}
						<Spacer mobile={50} />
						{renderButtons(buttons)}
						<Spacer mobile={70} />
					</div>
				</NumberedContent>
			</Container>
			<div className="hidden-mobile">
				<Spacer desktop={40} />
				<Background light floated>
					<Container left={136} right={136}>
						<Grid align="middle-xs">
							<Grid.Col width="md-14,lg-13">
								{renderButtons(buttons)}
							</Grid.Col>
							<Grid.Col width="md-10,lg-11">
								<div className={styles.imageWrapper}>
									<img
										alt="Collab"
										className={styles.image}
										src={acf.homepage_collab_image}
										loading="lazy"
									/>
								</div>
							</Grid.Col>
						</Grid>
					</Container>
				</Background>
			</div>
		</React.Fragment>
	);
};

export default CollabSection;
