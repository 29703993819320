import React from 'react';
import cn from 'classnames';

import {
	Container,
	NumberedContent,
	Spacer,
	LogoList,
	CircleButton,
} from '../../../components';
import { useAssetsContext } from '../../../assetsContext';
import { getPagePathByTitle } from '../../../utils/helpers';

import styles from './brandsSection.module.scss';

const BrandsSection = () => {
	const { locale, pages, acf = {} } = useAssetsContext();

	return (
		<Container left={136} right={136}>
			<Spacer desktop={180} mobile={50} />
			<NumberedContent
				number={5}
				title={acf.homepage_brands_title}
				indent
				large
			>
				<p
					className={cn(
						'f-large',
						'color-black-light',
						styles.description
					)}
				>
					{acf.homepage_brands_description}
				</p>
			</NumberedContent>
			<Spacer desktop={80} mobile={40} />
			<LogoList
				logos={acf.homepage_brands_logos.map(({ image, name }) => ({
					image,
					alt: name,
				}))}
			/>
			<Spacer desktop={60} mobile={40} />
			<div className={styles.btnContainer}>
				<CircleButton
					link={getPagePathByTitle('Brands', locale, pages)}
					text={acf.read_more}
					small
				/>
			</div>
			<Spacer desktop={120} />
		</Container>
	);
};

export default BrandsSection;
