import React from 'react';

import {
	Grid,
	Image,
	CircleButton,
	Container,
	Background,
	Spacer,
} from '../../../components';
import { useAssetsContext } from '../../../assetsContext';
import { getPagePathByTitle } from '../../../utils/helpers';

import styles from './introSection.module.scss';

const IntroSection = () => {
	const { locale, pages, acf = {} } = useAssetsContext();

	return (
		<Background light>
			<Container
				className={styles.container}
				innerRelative
				left={136}
				right={0}
			>
				<Grid no-gutter="all">
					<Grid.Col width="md-12">
						<div className={styles.imagesWrapper}>
							<Image
								className={styles.image}
								alt="intro-image"
								src={acf.homepage_background_image}
							/>
							<Image
								src={acf.homepage_background_image_product}
								alt="Product"
								className={styles.productImage}
							/>
						</div>
					</Grid.Col>
					<Grid.Col className="hidden-mobile" width="md-12">
						<Background>
							<div className={styles.desktopContent}>
								<Spacer desktop={80} />
								<h3 className="color-white">01</h3>
								<Spacer desktop={30} />
								<h1 className="h4">{acf.homepage_title}</h1>
							</div>
						</Background>
					</Grid.Col>
				</Grid>
				<div className={styles.innerContainer}>
					<div className="hidden-desktop">
						<h3 className="color-white">01</h3>
						<h1 className="h4 color-white">{acf.homepage_title}</h1>
						<Spacer mobile={10} />
					</div>
					<div className={styles.btnContainer}>
						<CircleButton
							className={styles.readMoreButton}
							link={getPagePathByTitle('About', locale, pages)}
							text={acf.read_more}
							color="white"
						/>
					</div>
				</div>
			</Container>
		</Background>
	);
};

export default IntroSection;
