import React from 'react';

import {
	Image,
	Grid,
	Container,
	NumberedContent,
	Spacer,
	BulletListItem,
	CircleButton,
} from '../../../components';
import { useAssetsContext } from '../../../assetsContext';
import { getPagePathByTitle } from '../../../utils/helpers';

import styles from './aboutUsSection.module.scss';

const AboutUsSection = () => {
	const { locale, pages, acf = {} } = useAssetsContext();

	return (
		<Container
			innerRelative
			left={136}
			right={0}
			className={styles.container}
		>
			<Grid no-gutter="all">
				<Grid.Col width="md-12">
					<Spacer mobile={30} desktop={120} />
					<NumberedContent
						number={2}
						title={acf.homepage_about_title}
						large
						indent
					>
						<Spacer desktop={40} />
						<ul className={styles.bulletList}>
							{acf.homepage_about_list?.map(
								({ text, icon }, index) => (
									<BulletListItem
										key={index}
										icon={icon}
										text={text}
									/>
								)
							)}
						</ul>
					</NumberedContent>
				</Grid.Col>
				<Grid.Col width="xs-12" className="hidden-mobile">
					<Image
						className={styles.image}
						alt="about-us-image"
						src={acf.homepage_about_background_image}
					/>
				</Grid.Col>
			</Grid>
			<Spacer mobile={10} desktop={0} />
			<div className={styles.btnContainer}>
				<CircleButton
					className={styles.readMoreButton}
					link={getPagePathByTitle('About', locale, pages)}
					text={acf.read_more}
					color="black"
				/>
			</div>
		</Container>
	);
};

export default AboutUsSection;
