import React from 'react';
import cn from 'classnames';

import {
	NumberedContent,
	Container,
	Spacer,
	SnusBoxList,
	CircleButton,
} from '../../../components';
import { useAssetsContext } from '../../../assetsContext';
import { getPagePathByTitle } from '../../../utils/helpers';

import styles from './productsSection.module.scss';

const ProducstSection = () => {
	const { locale, pages, acf = {} } = useAssetsContext();

	return (
		<Container className={styles.container} left={136} right={136}>
			<Spacer desktop={200} mobile={30} />
			<NumberedContent
				number={3}
				title={acf.homepage_products_title}
				indent
				large
			>
				<p
					className={cn(
						'f-large',
						'color-black-light',
						styles.description
					)}
				>
					{acf.homepage_products_description}
				</p>
			</NumberedContent>
			<Spacer mobile={30} desktop={40} />
			<SnusBoxList
				align="between-xs"
				colWidth="xs-12,sm-8,md-5"
				data={acf.homepage_popular_products}
				cols={5}
			/>
			<Spacer mobile={20} />
			<div className={styles.btnContainer}>
				<CircleButton
					link={getPagePathByTitle('NicotinePouches', locale, pages)}
					text={acf.discover_more}
					small
				/>
			</div>
			<Spacer desktop={80} />
		</Container>
	);
};

export default ProducstSection;
