import React from 'react';
import { graphql } from 'gatsby';

import { Layout } from '../components';
import AssetsContext from '../assetsContext';

import {
	IntroSection,
	AboutUsSection,
	ProducstSection,
	RetailersSection,
	BrandsSection,
	WhatAreSection,
	CollabSection,
} from '../containers/home';

const HomepagePage = ({
	pageContext,
	data: {
		wordpressPage: { acf: translations },
	},
}) => (
	<Layout
		title={translations.seo_title}
		description={translations.seo_description}
		image={translations.seo_cover_image}
		pageContext={pageContext}
	>
		<AssetsContext.Provider value={{ ...pageContext, acf: translations }}>
			<IntroSection />
			<AboutUsSection />
			<ProducstSection />
			<RetailersSection />
			<BrandsSection />
			<WhatAreSection />
			<CollabSection />
		</AssetsContext.Provider>
	</Layout>
);

export default HomepagePage;

export const query = graphql`
	query($id: Int, $lang: String) {
		wordpressPage(
			wordpress_id: { eq: $id }
			wpml_current_locale: { eq: $lang }
		) {
			wpml_current_locale
			acf {
				seo_title
				seo_description
				seo_cover_image
				homepage_about_list {
					icon
					text
				}
				homepage_about_title
				homepage_brands_description
				homepage_brands_title
				homepage_products_description
				homepage_retailers_description
				homepage_products_title
				homepage_retailers_title
				homepage_title
				homepage_collab_title
				homepage_collab_links {
					text
				}
				homepage_what_are_texts {
					text
					title
				}
				homepage_what_are_title
				read_more
				discover_more
				homepage_collab_image
				homepage_about_background_image
				homepage_popular_products {
					image
					subtitle
					title
				}
				homepage_background_image
				homepage_background_image_product
				homepage_brands_logos {
					image
					name
				}
				homepage_retailers_logos {
					name
					image
				}
			}
		}
	}
`;
