import React from 'react';
import cn from 'classnames';

import {
	Grid,
	TextCard,
	Background,
	Container,
	NumberedContent,
	Spacer,
	CircleButton,
} from '../../../components';
import { useAssetsContext } from '../../../assetsContext';
import { getPagePathByTitle } from '../../../utils/helpers';

import styles from './whatAreSection.module.scss';

const WhatAreSection = () => {
	const { locale, pages, acf = {} } = useAssetsContext();

	return (
		<Background floated slideUp>
			<Container left={136} right={136}>
				<Spacer desktop={100} mobile={60} />
				<NumberedContent light number={6} indent>
					<Grid no-gutter="vertical">
						<Grid.Col width="md-12">
							<h2 className="h1">
								{acf.homepage_what_are_title}
							</h2>
							<Spacer desktop={60} mobile={30} />
							<div className={styles.cardWrapper}>
								{acf.homepage_what_are_texts[0] && (
									<TextCard
										title={
											acf.homepage_what_are_texts[0].title
										}
										text={
											acf.homepage_what_are_texts[0].text
										}
									/>
								)}
							</div>
							<Spacer desktop={0} mobile={20} />
						</Grid.Col>
						<Grid.Col width="md-12">
							<Spacer desktop={80} />
							<div
								className={cn(
									styles.cardWrapper,
									styles.cardWrapperRight
								)}
							>
								{acf.homepage_what_are_texts[1] && (
									<TextCard
										title={
											acf.homepage_what_are_texts[1].title
										}
										text={
											acf.homepage_what_are_texts[1].text
										}
									/>
								)}
							</div>
							<Spacer desktop={70} mobile={20} />
							<div
								className={cn(
									styles.cardWrapper,
									styles.cardWrapperRight
								)}
							>
								{acf.homepage_what_are_texts[2] && (
									<TextCard
										title={
											acf.homepage_what_are_texts[2].title
										}
										text={
											acf.homepage_what_are_texts[2].text
										}
									/>
								)}
							</div>
						</Grid.Col>
					</Grid>
					<div className={styles.btnContainer}>
						<CircleButton
							link={getPagePathByTitle(
								'NicotinePouches',
								locale,
								pages
							)}
							text={acf.read_more}
						/>
					</div>
				</NumberedContent>
				<Spacer mobile={30} />
			</Container>
		</Background>
	);
};

export default WhatAreSection;
