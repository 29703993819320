import React from 'react';
import cn from 'classnames';

import {
	Background,
	Container,
	NumberedContent,
	Spacer,
	CircleButton,
	LogoList,
} from '../../../components';

import styles from './retailersSection.module.scss';
import { useAssetsContext } from '../../../assetsContext';
import { getPagePathByTitle } from '../../../utils/helpers';

const RetailersSection = () => {
	const { locale, pages, acf = {} } = useAssetsContext();

	return (
		<Background floated slideUp>
			<Container left={136} right={136}>
				<Spacer desktop={180} mobile={60} />
				<NumberedContent
					number={4}
					title={acf.homepage_retailers_title}
					indent
					large
					light
				>
					<p
						className={cn(
							'f-large',
							'color-black-light',
							styles.description
						)}
					>
						{acf.homepage_retailers_description}
					</p>
				</NumberedContent>
				<Spacer mobile={60} />
				<LogoList
					logos={acf.homepage_retailers_logos.map(
						({ image, name }) => ({
							image,
							alt: name,
						})
					)}
				/>
				<Spacer desktop={60} mobile={20} />
				<div className={styles.btnContainer}>
					<CircleButton
						link={getPagePathByTitle('Retailers', locale, pages)}
						text={acf.read_more}
						small
					/>
				</div>
				<Spacer desktop={30} />
			</Container>
		</Background>
	);
};

export default RetailersSection;
